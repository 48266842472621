import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const styles1 = theme => ({
    icon: {
        fontSize: 20
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing()
    },
    message: {
        display: 'flex',
        alignItems: 'center'
    },
});

export default function DialogResponsiveFuncBase(props) {
    const { classes, openDialog, onClose, title, actionButtons, closeButtonName, children } = props;

    return (
        <div>
            <Dialog
                fullWidth={true}
                open={openDialog}
                onClose={onClose}
                aria-labelledby="responsive-dialog-title">
                <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    {children || ""}
                </DialogContent>
                <DialogActions>
                    {actionButtons || ""}
                    {(closeButtonName !== null && typeof closeButtonName !== 'undefined' && closeButtonName.length > 0) &&
                        <Button onClick={onClose} id="dialog-cancel" color="primary">{closeButtonName}</Button>
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
}

DialogResponsiveFuncBase.defaultProps = {};

const mapStateToProps = state => ({});

let connectedApp = connect(mapStateToProps, null)(withStyles(styles1)(DialogResponsiveFuncBase));

export { connectedApp as DialogResponsiveFuncBase };