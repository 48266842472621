import { http, buildRequestHeaders} from './axiosBase'

export const firearmsApi = {
    all,
    get,
    update,
    remove
};

const firearmUrl = '/firearms';


async function all() {
    var headers = await buildRequestHeaders({ addAuth: true });

    return http.get(firearmUrl, {
        headers: headers,
    });
}
async function get(uid) {
    var headers = await buildRequestHeaders({ addAuth: true });

    return http.get(`${firearmUrl}/${uid}`, {
        headers: headers,
    });
}

async function update(changes) {
    var headers = await buildRequestHeaders({ addAuth: true });

    return http.post(`${firearmUrl}`, changes, {
        headers: headers,
    });
}

async function remove(uid) {
    var headers = await buildRequestHeaders({ addAuth: true });

    return http.delete(`${firearmUrl}/${uid}`, {
        headers: headers,
    });
}