import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';

import { userService } from '../../services';
import { alertActions } from '../../actions';

import { paths } from '../../constants';
import DialogAuthSetup from '../../components/Dialogs/DialogAuthSetup'
import DialogAuthRecoveryCodes from '../../components/Dialogs/DialogAuthRecoveryCodes'
import DialogConfirmation from '../../components/Dialogs/DialogConfirmation'


const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    header: {
        'font-size': '25pt'
    },
    qrcode: {
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block'
    }
});

const defaultDetails = {
    "Username": "",
    "FirstName": "",
    "LastName": "",
    "Email": "",
    "Phone": "",
    "HasAuthenticator": false,
    "IsTwoFactorEnabled": false
};

const chgPassDetails = {
    oldPassword: '',
    newPassword: '',
    conPassword: ''
};
const tfaDefault = {
    AuthenticatorUri: '',
    SharedKey: ''
};
export default function SecuritySettings(props) {
    const { classes } = props;
    const [loading, setLoading] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [details, setDetails] = useState(defaultDetails);
    const [chgPass, setChgPass] = useState(chgPassDetails);
    const [errors, setErrors] = useState();
    const [authSetupDialogOpen, setAuthSetupDialogOpen] = useState(false);
    const [authDisableDialogOpen, setAuthDisableDialogOpen] = useState(false);
    const [authResetAppDialogOpen, setAuthResetAppDialogOpen] = useState(false);
    const [authReGenDialogOpen, setAuthReGenDialogOpen] = useState(false);
    const [recCodesDialogOpen, setRecCodesDialogOpen] = useState(false);
    const [tfAuth, setTfAuth] = useState(tfaDefault);
    const [recCode, setRecCode] = useState("");

    const handleValidation = (e) => {
        let errors = {};

        if (chgPass.newPassword === "") {
            errors["newPassword"] = "Cannot be empty";
        }
        if (chgPass.oldPassword === "") {
            errors["oldPassword"] = "Cannot be empty";
        }
        else if (chgPass.conPassword === "") {
            errors["conPassword"] = "Cannot be empty";
        }
        else {
            if (chgPass.newPassword !== chgPass.conPassword) {
                errors["conPassword"] = "New Password does not match the Confirm Password.";
            }
        }

        setErrors(errors, []);
        return Object.keys(errors).length === 0;
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        setSubmitted(true);
        if (handleValidation(chgPass)) {
            userService.changePassword(chgPass)
                .then(
                    user => {
                        props.dispatch(alertActions.success('password change successful'));
                    },
                    error => {
                        props.dispatch(alertActions.error(error.toString()));
                    });
        }
    }

    const handleAuthSetupShow = (e) => {
        userService.getTwoFactAuth()
            .then(
                setup => {
                    setTfAuth(setup)
                    setAuthSetupDialogOpen(true);
                },
                error => {
                    props.dispatch(alertActions.error(error));
                }
            );

    }

    const handleAuthErrors = (e) => {
        props.dispatch(alertActions.error(e));
    }

    const handleAuthSetupClose = (e) => {
        if (typeof e !== 'undefined' && e !== '' && Array.isArray(e)) {
            userService.get()
                .then(resultResponse => {
                    if (resultResponse) {
                        setDetails(resultResponse, []);
                    }
                });
            setRecCode(e);
            setRecCodesDialogOpen(true);
            setAuthSetupDialogOpen(false);
        } else {
            props.dispatch(alertActions.success("Your authenticator app has been verified."));
            setAuthSetupDialogOpen(false);
        }
    }

    const handleAuthDisableClose = (e) => {

        userService.disableTwoFactAuth()
            .then(
                disable => {
                    userService.refreshInfo()
                        .then(
                            user => {
                                setAuthDisableDialogOpen(false);
                                userService.get()
                                    .then(resultResponse => {
                                        if (resultResponse) {
                                            setDetails(resultResponse, []);
                                        }
                                        props.dispatch(alertActions.success("Two factor authentication has been disabled."));
                                    });
                            }).catch(
                                error => {
                                    handleAuthErrors(error);
                                });
                },
                error => {
                    //dispatch(failure(error));
                    props.dispatch(alertActions.error("Something went wrong, while trying to disable two factor authentication"));
                }
            );
    }

    const handleResetAppDialogClose = () => {
        userService.resetTwoFactAuth()
            .then(
                reset => {
                    userService.refreshInfo()
                        .then(
                            user => {
                                setAuthDisableDialogOpen(false);
                                userService.get()
                                    .then(resultResponse => {
                                        if (resultResponse) {
                                            setDetails(resultResponse, []);
                                        }
                                        setAuthResetAppDialogOpen(false);
                                        props.dispatch(alertActions.success("The key for your authenticator app has been reset. you will need to re-setup the app with the new key."));
                                    });
                            }).catch(
                                error => {
                                    handleAuthErrors(error);
                                });
                },
                error => {
                    props.dispatch(alertActions.success("Something went wrong, while trying to reset the authenticator key."));
                }
            );
    }

    const handleAuthReGenDialogClose = () => {
        userService.getRecoveryCodes()
            .then(
                codes => {
                    if (typeof codes.RecoveryCodes !== 'undefined' && Array.isArray(codes.RecoveryCodes) && codes.RecoveryCodes.length) {
                        setRecCode(codes.RecoveryCodes);
                        setRecCodesDialogOpen(true);

                    } else {
                        props.dispatch(alertActions.error("No recovery codes to display."));
                    }
                },
                error => {
                    props.dispatch(alertActions.error(error));
                }
            );
        setAuthReGenDialogOpen(false);
    }

    const renderAuthButtons = () => {
        if (details !== null) {

            if (!details.IsTwoFactorEnabled) {
                return <Grid className={classes.profileContainer} container spacing={1}>
                    <Grid item xs={12}>
                        <Button type="submit" size="medium" fullWidth variant="contained" color="primary" className={
                            classes.submit} onClick={e => handleAuthSetupShow(e)} >Enable two factor authenticator app</Button>
                    </Grid>
                </Grid>;
            }
            else {
                if (!details.HasAuthenticator) {
                    return <Grid className={classes.profileContainer} container spacing={1}>
                        <Grid item xs={12}>
                            <Button type="submit" size="medium" fullWidth variant="contained" color="primary" className={
                                classes.submit} onClick={e => handleAuthSetupShow(e)} >Add authenticator app</Button>
                        </Grid>
                    </Grid>;
                }
                else {
                    return <Grid className={classes.profileContainer} container spacing={1}>
                        <Grid item xs={6}>
                            <Button type="submit" size="medium" fullWidth variant="contained" color="primary" className={
                                classes.submit} onClick={e => handleAuthSetupShow(e)} >Setup authenticator app</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button type="submit" size="medium" fullWidth variant="contained" color="primary" className={
                                classes.submit} onClick={e => setAuthResetAppDialogOpen(true)}>Reset authenticator app</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button type="submit" size="medium" fullWidth variant="contained" color="primary" className={
                                classes.submit} onClick={e => setAuthDisableDialogOpen(true)} >Disable 2FA</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button type="submit" size="medium" fullWidth variant="contained" color="primary" className={
                                classes.submit} onClick={e => setAuthReGenDialogOpen(true)}>Reset recovery codes</Button>
                        </Grid>
                    </Grid>;
                }
            }

        }
    }

    useEffect(() => {
        if (loading) {
            userService.get()
                .then(resultResponse => {
                    if (resultResponse) {
                        setDetails(resultResponse, []);
                        setLoading(false);
                    }
                });
        }
    }, []);

    return (
        <div>
            <Paper className={classes.root} elevation={1}>
                <Typography variant="h2" className={classes.header} gutterBottom>
                    Security Settings
                </Typography>
                {loading &&
                    <div>Loading...</div>}
                {!loading &&
                    <form className={classes.form} name="form" onSubmit={e => handleSubmit(e)}>
                        <Grid className={classes.ChangePasswordContainer} container spacing={1}>
                            <Grid item xs={12}>
                                <FormControl margin="normal" fullWidth>
                                    <TextField id="oldPassword" name="oldPassword" type="password" label="Current Password"
                                        value={chgPass.oldPassword}
                                        onChange={e => setChgPass({ ...chgPass, [e.target.name]: e.target.value }, [])}
                                        helperText={(submitted && typeof errors["oldPassword"] !== 'undefined'
                                            ? errors["oldPassword"]
                                            : '')}
                                        error={(submitted && typeof errors["oldPassword"] !== 'undefined')}
                                        autoComplete="current-password" required />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl margin="normal" fullWidth>
                                    <TextField id="newPassword" name="newPassword" type="password" label="New Password"
                                        value={chgPass.newPassword}
                                        onChange={e => setChgPass({ ...chgPass, [e.target.name]: e.target.value }, [])}
                                        helperText={(submitted && typeof errors["newPassword"] !== 'undefined'
                                            ? errors["newPassword"]
                                            : '')}
                                        error={(submitted && typeof errors["newPassword"] !== 'undefined')}
                                        autoComplete="new-password" required />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl margin="normal" fullWidth>
                                    <TextField id="conPassword" name="conPassword" type="password" label="Confirm Password"
                                        value={chgPass.conPassword}
                                        onChange={e => setChgPass({ ...chgPass, [e.target.name]: e.target.value }, [])}
                                        helperText={(submitted && typeof errors["conPassword"] !== 'undefined'
                                            ? errors["conPassword"]
                                            : '')}
                                        error={(submitted && typeof errors["conPassword"] !== 'undefined')}
                                        required />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <Button type="submit" size="medium" fullWidth variant="contained" color="primary"
                                    className={classes.submit}>Update</Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button size="medium" fullWidth variant="contained" color="secondary"
                                    className={classes.submit} component={Link} to={paths.ACCOUNT}>Cancel</Button>
                            </Grid>
                        </Grid>
                    </form>}
            </Paper>
            <Paper className={classes.root} elevation={1}>
                <Typography variant="h2" className={classes.header} gutterBottom>
                    Two-factor Authentication
                </Typography>
                {renderAuthButtons()}
            </Paper>

            {authSetupDialogOpen &&
                <DialogAuthSetup
                    dialogOpen={authSetupDialogOpen}
                    onClose={() => { setTfAuth(tfaDefault); setAuthSetupDialogOpen(false); }}
                    handleSubmit={handleAuthSetupClose}
                    handleAuthErrors={handleAuthErrors}
                    tfAuth={tfAuth}
                />
            }
            {recCodesDialogOpen &&
                <DialogAuthRecoveryCodes
                    dialogOpen={recCodesDialogOpen}
                    onClose={() => { setRecCodesDialogOpen(false); }}
                    recoveryCodes={recCode}
                />
            }
            {authDisableDialogOpen &&
                <DialogConfirmation
                    dialogOpen={authDisableDialogOpen}
                    onClose={() => { setAuthDisableDialogOpen(false); }}
                    handleSubmit={handleAuthDisableClose}
                    dialogMessage={"Disabling 2FA does not change the keys used in authenticator apps. If you wish to change the key used in an authenticator app you should reset your authenticator keys."}
                    dialogTitle={"Disable 2FA?"}
                    dialogBtnName={"Continue"}
                />}

            {authReGenDialogOpen &&
                <DialogConfirmation
                    dialogOpen={authReGenDialogOpen}
                    onClose={() => { setAuthReGenDialogOpen(false); }}
                    handleSubmit={handleAuthReGenDialogClose}
                    dialogMessage={"Generating new recovery codes does not change the keys used in authenticator apps. If you wish to change the key used in an authenticator app you should reset your authenticator keys."}
                    dialogTitle={"Generate New Recovery Codes?"}
                    dialogBtnName={"Continue"}
                />}

            {authResetAppDialogOpen &&
                <DialogConfirmation
                    dialogOpen={authResetAppDialogOpen}
                    onClose={() => { setAuthResetAppDialogOpen(false); }}
                    handleSubmit={handleResetAppDialogClose}
                    dialogMessage={"If you reset your authenticator key your authenticator app will not work until you reconfigure it. This process disables 2FA until you verify your authenticator app. If you do not complete your authenticator app configuration you may lose access to your account."}
                    dialogTitle={"Reset Your Authenticator?"}
                    dialogBtnName={"Continue"}
                />}

        </div>
    );
}

const mapStateToProps = state => ({});

let connectedApp = compose(
    withStyles(styles, { name: 'SecuritySettings' }),
    connect(mapStateToProps, null)
)(SecuritySettings);

export { connectedApp as SecuritySettings };