import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { alertActions } from '../../actions';
import { paths } from '../../constants';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormHelperText from "@material-ui/core/FormHelperText";
import InputAdornment from '@material-ui/core/InputAdornment';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { firearmsService, fireSessionService, venueService} from '../../services';
import DialogConfirmation from '../../components/Dialogs/DialogConfirmation'

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    header: {
        'font-size': '25pt'
    },
    firearmDetails: {
        'font-size': '10pt',
        'margin-right': '1%'
    },
    PendingDelete: {
        'color': 'grey',
    },
    "firearmDetails input": {
        'font-size': '10pt',
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    fabGreen: {
        color: theme.palette.common.white,
    }
});

const defaultDetails = {
    "Venue": {
        "Name": ""
    },
    "Firearms": [
    ],
    "Notes": "",
    "UId": "",
    "DateSession": ""
};

export default function FireSessionDetails(props) {

    let content;
    const navigate = useNavigate();

    const { classes } = props;
    const [addFirearmUId, setAddFirearmUId] = useState();
    const [addVenueUId, setAddVenueUId] = useState();
    const [firearmsSelect, setFirearmsSelect] = useState([]);
    const [venueSelect, setVenueSelect] = useState([]);
    const [details, setDetails] = useState(defaultDetails);
    const [isSavedDialogOpen, setIsSavedDialogOpen] = useState();
    const [errors, setErrors] = useState();

    const params = useParams();

    const Uid = params.uid

    const handleSubmit = (e) => {
        e.preventDefault();

        fireSessionService.update(details)
            .then(
                success => {
                    setIsSavedDialogOpen(true);
                },
                error => {
                    props.dispatch(alertActions.error(error));
                }
            );
    }

    const handleGetDetails = () => {
        firearmsService.all()
            .then(firearmResponse => {
                if (firearmResponse) {
                    setFirearmsSelect(firearmResponse, []);
                }
                venueService.all(1)
                    .then(venueResponse => {
                        if (venueResponse) {
                            setVenueSelect(venueResponse, []);
                        }
                        if (typeof Uid !== 'undefined' && Uid.length > 0) {
                            fireSessionService.get(Uid)
                                .then(
                                    fireSessions => {
                                        setDetails(fireSessions, []);
                                        setAddVenueUId(fireSessions.Venue.UId);

                                    });
                        }
                    });
            });
    }
 
    const handleAddToFiredList = (e) => {

        if (typeof addFirearmUId !== "undefined" && addFirearmUId.length > 0) {
            let name = firearmsSelect.find(f => { return f.UId === addFirearmUId }).Name || "";
            let alreadyInList = typeof details.Firearms.find(f => { return f.UId === addFirearmUId }) !== "undefined";

            if (name.length > 0 && !alreadyInList) {
                let newItem = {
                    UId: addFirearmUId,
                    Name: name,
                    Delete: false,
                    RoundsFired: 0,
                    Distance: {
                        Value: 0,
                        Units: "ft"
                    },
                    Misfires: 0,
                    Grouping: {
                        Value: 0,
                        Units: "in"
                    }
                };
                setDetails({ ...details, Firearms: [...details.Firearms, newItem] });

            }
        }
    }
    const handleEditFiredList = (e, fUid, parentNode) => {
        const { name, value } = e.target;
        var numValue = Number(value);

        if (fUid.length > 0 && !isNaN(numValue)) {

            if (typeof parentNode !== 'undefined' && parentNode.length > 0) {
                setDetails({
                    ...details, Firearms: details.Firearms.map(firearm =>
                        firearm.UId === fUid
                            ? {
                                ...firearm,
                                [parentNode]: { ...firearm[parentNode], [name]: numValue }
                            }
                            : firearm,
                    ),
                }, []);
            }
            else {
                setDetails({
                    ...details, Firearms: details.Firearms.map(firearm =>
                        firearm.UId === fUid
                            ? {
                                ...firearm,
                                [name]: numValue
                            }
                            : firearm,
                    ),
                }, []);
            }
        }
    }
    const handleDeleteFromFiredList = (e) => {

        if (e.length > 0) {
            setDetails({
                ...details, Firearms: details.Firearms.map(firearm =>
                    firearm.UId === e
                        ? {
                            ...firearm,
                            Delete: true,
                        }
                        : firearm,
                ),
            });
        }
    }
    const handleRestoreToFiredList = (e) => {
        if (e.length > 0) {

            if (e.length > 0) {
                setDetails({
                    ...details, Firearms: details.Firearms.map(firearm =>
                        firearm.UId === e
                            ? {
                                ...firearm,
                                Delete: false,
                            }
                            : firearm,
                    ),
                });
            }
        }
    }
    const handleSelectChange = (e) => {
        setAddVenueUId(e.target.value);
        setDetails({ ...details, ["Venue"]: { ...details["Venue"], ["UId"]: e.target.value } }, [])
    };

    useEffect(() => {
        handleGetDetails();
    }, []);

    return (
        <div>
            <Paper className={classes.root} elevation={1}>
                <Typography variant="h2" className={classes.header} gutterBottom>
                    Range Sessions Details
                </Typography>
                <form className={classes.form} name="form" onSubmit={e => { handleSubmit(e) }}>
                    <Grid className={classes.profileContainer} container spacing={1}>
                        <Grid item xs={12}>
                            <Accordion defaultExpanded={true}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="session-details-content"
                                    id="session-details-header">
                                    <Typography className={classes.heading}>Session Details</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid className={classes.profileContainer} container spacing={1}>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl margin="normal" fullWidth>
                                                <TextField className={classes.formControl}
                                                    select
                                                    label="Venue"
                                                    onChange={e => handleSelectChange(e)}
                                                    value={addVenueUId ?? " "}
                                                className={classes.selectEmpty}>
                                                    {venueSelect.map((venue) =>
                                                        <MenuItem key={venue.UId} value={venue.UId}>{venue.Name}</MenuItem>
                                                    )}
                                                </TextField>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl margin="normal" fullWidth>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <DatePicker disableToolbar variant="inline" format="MM/dd/yyyy" autoOk={true}
                                                        onChange={date => setDetails({ ...details, ["DateSession"]: moment(date, 'YYYY-MM-DD').format('MM/DD/YYYY') }, [])}
                                                        id="DateSession" name="DateSession" label="Session Date"
                                                        value={(typeof details.DateSession !== 'undefined' && details.DateSession.length > 0) ? details.DateSession : null}
                                                        keyboardbuttonprops={{
                                                            'aria-label': 'change date',
                                                        }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl margin="normal" required fullWidth>
                                                <TextField id="Notes" name="Notes" label="Notes" multiline maxRows={4}
                                                    value={details.Notes}
                                                    onChange={e => setDetails({ ...details, [e.target.name]: e.target.value }, [])}
                                                />
                                            </FormControl>
                                        </Grid>

                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion defaultExpanded={true}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="firearms-used-content"
                                    id="firearms-used-header"
                                >
                                    <Typography className={classes.heading}>Firearms Used</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid className={classes.firearmsContainer} container spacing={1}>
                                        <Grid item sm={8}>
                                            <Select className={classes.formControl} fullWidth
                                                onChange={e => setAddFirearmUId(e.target.value)}
                                                value={addFirearmUId}
                                                className={classes.selectEmpty}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem key={''} value={''}>
                                                    <em>None</em>
                                                </MenuItem>
                                                {firearmsSelect.map((firearm) =>
                                                    <MenuItem key={firearm.UId} value={firearm.UId}>{firearm.Name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item sm={4}>
                                            <Button variant="contained" color="primary" className={classes.addFirearm} onClick={handleAddToFiredList} fullWidth>Add</Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <List dense={true}>
                                                {details.Firearms.map((item) =>
                                                    <ListItem key={item.UId}>
                                                        <Grid container spacing={1}>
                                                            <Grid item sm={12}>
                                                                <Typography variant="body1" className={`${classes.firearmDetails} ${item.Delete ? classes.PendingDelete : ""}`} gutterBottom >
                                                                    {item.Name} {item.Delete ? " - Pending Delete" : ""}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item sm={3}>
                                                                <FormControl fullWidth>
                                                                    <TextField id="RoundsFired" name="RoundsFired" type="text" size="small" type="number"
                                                                        className={classes.firearmDetails}
                                                                        placeholder={"0"}
                                                                        aria-describedby="rounds-fired"
                                                                        defaultValue={item.RoundsFired}
                                                                        onChange={e => handleEditFiredList(e, item.UId)}
                                                                        disabled={item.Delete} />

                                                                    <FormHelperText id="rounds-fired">Rounds Fired</FormHelperText>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item sm={3}>
                                                                <FormControl fullWidth>
                                                                    <TextField id="Misfires" name="Misfires" type="text" size="small" type="number"
                                                                        className={classes.firearmDetails}
                                                                        placeholder={"0"}
                                                                        aria-describedby="misfires"
                                                                        defaultValue={item.Misfires}
                                                                        onChange={e => handleEditFiredList(e, item.UId)}
                                                                        disabled={item.Delete} />

                                                                    <FormHelperText id="misfires">MisFires</FormHelperText>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item sm={3}>
                                                                <FormControl fullWidth>
                                                                    <TextField id="Distance" name="Value" type="text" size="small" type="number"
                                                                        className={classes.firearmDetails}
                                                                        defaultValue={item.Distance.Value}
                                                                        placeholder={"0"}
                                                                        aria-describedby="target-distance"
                                                                        InputProps={{
                                                                            endAdornment: <InputAdornment position="end">Ft.</InputAdornment>,
                                                                        }}
                                                                        onChange={e => handleEditFiredList(e, item.UId, "Distance")}
                                                                        disabled={item.Delete} />
                                                                    <FormHelperText id="target-distance">Distance</FormHelperText>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item sm={3}>
                                                                <FormControl fullWidth>
                                                                    <TextField id="Grouping" name="Value" type="text" size="small" type="number"
                                                                        className={classes.firearmDetails}
                                                                        defaultValue={item.Grouping.Value}
                                                                        placeholder={"0"}
                                                                        aria-describedby="target-grouping"
                                                                        InputProps={{
                                                                            endAdornment: <InputAdornment position="end">In.</InputAdornment>,
                                                                        }}
                                                                        onChange={e => handleEditFiredList(e, item.UId, "Grouping")}
                                                                        disabled={item.Delete} />
                                                                    <FormHelperText id="target-distance">Grouping</FormHelperText>
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>

                                                        <ListItemSecondaryAction>
                                                            {!item.Delete &&
                                                                <IconButton edge="end" aria-label="delete" onClick={e => handleDeleteFromFiredList(item.UId)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            }
                                                            {item.Delete &&
                                                                <IconButton edge="end" aria-label="undelete" onClick={e => handleRestoreToFiredList(item.UId)}>
                                                                    <RestoreFromTrashIcon />
                                                                </IconButton>
                                                            }
                                                        </ListItemSecondaryAction>
                                                    </ListItem>,
                                                )}
                                            </List>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={6}>
                            <Button type="submit" size="medium" fullWidth variant="contained" color="primary" className={classes.submit}>Save</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button size="medium" fullWidth variant="contained" color="secondary" className={classes.submit} component={Link} to={paths.FIRESESSIONS} >Cancel</Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
            {isSavedDialogOpen &&
                <DialogConfirmation
                dialogOpen={isSavedDialogOpen}
                    onClose={() => {
                        setIsSavedDialogOpen(false);
                        handleGetDetails();

                    }}
                    handleSubmit={e => navigate(paths.FIRESESSIONS)}
                    dialogMessage={"Your changes have been successfully saved. Would you like to return to Sessions List?"}
                dialogTitle={"Changes Successfully Saved!!"}
                    dialogBtnName={"Return to Sessions"}
                />}
        </div>
    );
}

const mapStateToProps = state => ({});

let connectedApp = compose(
    withStyles(styles, { name: 'FireSessionDetails' }),
    connect(mapStateToProps, null)
)(FireSessionDetails);

export { connectedApp as FireSessionDetails };

