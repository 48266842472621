import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import { fireSessionService } from '../../services';
import DialogConfirmation from '../../components/Dialogs/DialogConfirmation'
import { paths } from '../../constants';

import List from '@material-ui/core/List';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';


import Fab from '@material-ui/core/Fab';

import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    header: {
        'font-size': '25pt'
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    fabGreen: {
        color: theme.palette.common.white,
    }
});

export default function FireSession(props) {
    const { classes } = props;
    const [loading, setLoading] = useState(true);
    const [deleteId, setDeleteId] = useState("");
    const [sessions, setSessions] = useState([]);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

    const handleDeleteDialogClose = (e) => {
        fireSessionService.remove(deleteId)
            .then(
                success => {
                    setDeleteId("");
                    setIsDeleteDialogOpen(false);
                    fireSessionService.all()
                        .then(apiResponse => {
                            if (apiResponse) {
                                setSessions(apiResponse, []);
                            }
                        });
                },
                error => {
                    setIsDeleteDialogOpen(false);
                }
            );
    }

    const handleDeleteDialogShow = (e) => {
        if (typeof e !== "undefined") {
            setDeleteId(e);
        }
        setIsDeleteDialogOpen(true);
    }

    useEffect(() => {
        if (loading) {
            fireSessionService.all()
                .then(apiResponse => {
                    if (apiResponse) {
                        setSessions(apiResponse, []);
                        setLoading(false);
                    }
                });
        }
    }, []);

    return (
        <div>
            <Paper className={classes.root} elevation={1}>
                <Typography variant="h2" className={classes.header} gutterBottom>
                    Range Sessions
                </Typography>
                {loading &&
                    <div>Loading...</div>}
                {!loading && sessions.length === 0 &&
                    <div>No Records Found.</div>}
                {!loading && sessions.length > 0 &&
                    <List dense={true}>
                        {sessions.map((item) =>
                            <ListItem key={item.UId}>
                                <ListItemAvatar>
                                    <Avatar>
                                        <FolderIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={item.DateSession}
                                    secondary={item.VenueName || ""}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="edit" href={`${paths.FIRESESSIONSDETAILS}/${item.UId}`}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton edge="end" aria-label="delete" onClick={e => handleDeleteDialogShow(item.UId)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>,
                        )}
                    </List>
                }
                <Fab aria-label="Add" className={classes.fab} color="primary" href={`${paths.FIRESESSIONSDETAILS}`}>
                    <AddIcon />
                </Fab>
            </Paper>
            {isDeleteDialogOpen &&
                <DialogConfirmation
                    dialogOpen={isDeleteDialogOpen}
                    onClose={() => {
                        setDeleteId("");
                        setIsDeleteDialogOpen(false);
                    }}
                    handleSubmit={handleDeleteDialogClose}
                    dialogMessage={"Are you sure you want to delete this?"}
                    dialogTitle={"Delete Range Session?"}
                    dialogBtnName={"Delete"}
                />}
        </div>
    );
}
const mapStateToProps = state => ({});

let connectedApp = compose(
    withStyles(styles, { name: 'FireSession' }),
    connect(mapStateToProps, null)
)(FireSession);

export { connectedApp as FireSession };