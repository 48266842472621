import React, { useEffect, useState } from "react";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import DialogResponsiveFuncBase from './DialogResponsiveFuncBase'
import moment from 'moment';
import { venueService } from '../../services';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider, KeyboardDatePicker
} from '@material-ui/pickers';

const styles = theme => ({

});

const defaultDetails = {
    "UId": "",
    "Name": "",
};

 function DialogRangeGun(props) {

    const { classes, Uid, dialogOpen, onClose, handleSubmit} = props;
    const [details, setDetails] = useState(defaultDetails);
    const [submitted, setSubmit] = useState();
    const [errors, setErrors] = useState();

    const handleFormSubmit = (e) => {
        e.preventDefault();

        let venueChanges = {
            UId: details.UId || "",
            Name: details.Name || "",
            VenueType: 1
        }

        venueService.update(venueChanges).then(successful => {
            if (successful) {
                handleSubmit();
            }
        });
    }

    useEffect(() => {
        if (details.Name.length == 0) {
            if (typeof Uid !== "undefined" && Uid.length > 0) {
                venueService.get(Uid)
                    .then(resultResponse => {
                        if (resultResponse) {
                            setDetails(resultResponse, []);
                        }
                    });
            }
        }
    });


    return (
        <DialogResponsiveFuncBase
            openDialog={dialogOpen}
            onClose={onClose}
            title={"Firearm - Edit"}
            actionButtons={<Button autoFocus form="update-range" type="submit" color="primary">
                {typeof details.UId !== 'undefined' && details.UId.length > 0 ? "Update": "Create"}
                </Button>}
            closeButtonName="Cancel">
            <form name="update-range" id="update-range" onSubmit={e => { handleFormSubmit(e) }}>
                <input type="hidden" id="UId" name="UId" value={details.UId ?? ""} />
                        <FormControl margin="normal" fullWidth>
                            <TextField id="Name" name="Name" type="text" label="Name"
                                value={details.Name} onChange={e => setDetails({ ...details, [e.target.name]: e.target.value }, [])}
                                helperText={(submitted && typeof errors["name"] !== 'undefined'
                                    ? errors["name"]
                                    : '')}
                                error={(submitted && typeof errors["name"] !== 'undefined')}
                                required />
                        </FormControl>
            </form>
        </DialogResponsiveFuncBase>
    );
}

export default withStyles(styles)(DialogRangeGun);
