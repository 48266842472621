import React, { Children } from 'react';
import { Navigate, Outlet, Route } from 'react-router-dom';
import { paths } from '../constants';
import { userLocalDb } from '../storelocal';
import { userService } from '../services';

import { history } from '../helpers';

/** Layouts **/
import LayoutAuth from "../layouts/LayoutAuth";
import LayoutDashboard from "../layouts/LayoutDashboard";

export const checkAuth = {
    isAuthenticated: false,
    authenticate() {
        userService.userAuth().then(isAuth => {
            this.isAuthenticated = isAuth;
        });
    },
    auth() {
        userService.userAuth().then(isAuth => {
            return isAuth;
        });
    },
    signout() {
        this.isAuthenticated = false;
        userService.logout();
        this.isAuthenticated = false;
        history.push(paths.LOGIN);
    }
};


//export const ProtectedRoute = (props) => {
//   let { component: Component, children, render, ...rest } = props

//   if (!userLocalDb.getAuthSync()) {
//       <Navigate to={{ pathname: paths.LOGIN, state: { from: location } }} />
//   }

//return
//    < LayoutDashboard >
//        <Component authentication={authentication}  {...props} />
//    </LayoutDashboard >;
//}

export const ProtectedRoute = (props) => {
    let {  location, ...rest } = props

    return userLocalDb.getAuthSync() ? <Outlet /> : <Navigate to={{ pathname: paths.LOGIN, state: { from: location || "" } }} replace/>
}


//export const AuthRoute = ({ component: Component, ...rest }) => (
//    <Route {...rest} render={props => (
//        <LayoutAuth>
//            <Component {...props} />
//        </LayoutAuth>
//    )} />
//)

//export const DashboardRoute = ({ component: Component, authentication, ...rest }) => {
//    return (<Route {...rest} render={props => (
//    userLocalDb.getAuthSync() ?
//        <LayoutDashboard>
//            <Component authentication={authentication}  {...props} />
//        </LayoutDashboard>
//        : <Navigate to={{ pathname: paths.LOGIN, state: { from: props.location } }} />
//    )} />);
//}