/**
 * src/api/http.js
 */
import axios from 'axios'
import { userLocalDb } from '../storelocal';


export async function buildRequestHeaders(params) {
    let headers = {
        'Content-Type': 'application/json'
    };

    if (params.addAuth) {
        let token = await userLocalDb.getAuth();

        if (typeof token !== "undefined" && token.length > 0) {
            headers.Authorization = 'Bearer ' + token;
        }
    }

    return headers
}

/**
 *
 * parse error response
 */
function parseError(messages) {
    // error
    if (messages) {
        if (messages instanceof Array) {
            return { messages: [messages] };
        } else {
            return { messages: messages };
        }
    } else {
        return { messages: ['An error occurred'] };
    }
}

/**
 * parse response
 */
function parseBody(response) {
    //  if (response.status === 200 && response.data.status.code === 200) { // - if use custom status code
    if (response.status === 200) {
        return response.data
    } else {
        if (response.data.length > 0) {
            return this.parseError(response.data)
        }
    }
}

/**
 * axios instance
 */
let instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_BASE}`
})

// request header
instance.interceptors.request.use((config) => {
    // Do something before request is sent
    return config
}, error => {
    return Promise.reject(error)
})

// response parse
instance.interceptors.response.use((response) => {
    return parseBody(response)
}, error => {
    console.warn('Error status', error.response.status)
    if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
        if (typeof error.response.data.tfaRequired !== "undefined" && error.response.data.tfaRequired) {
            return Promise.reject({
                requiresTwoFactor: true,
                isLockedOut: (typeof error.response.data.isLockedOut !== "undefined" && error.response.data.isLockedOut),
                message: "Unathorized. If logged in, please try logging out, then back in."
            });
        }
        return new Promise((resolve) => {
            const originalRequest = error.config
            userLocalDb.getId()
                .then(id => {
                    if (id) {
                        originalRequest._retry = true

                        const response = fetch(`${process.env.REACT_APP_API_BASE}/auth/refreshaccesstoken?userId=${id}`, {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        })
                            .then((res) => res.json())
                            .then((res) => {
                                if (typeof res.token !== "undefined" && res.token.length > 0) {
                                    userLocalDb.insertAuth(res.token)
                                    if (typeof originalRequest.headers.Authorization !== "undefined" && originalRequest.headers.Authorization.length > 0) {
                                        originalRequest.headers.Authorization = `Bearer ${res.token}`;
                                    }
                                }
                                return axios(originalRequest)
                                    .catch(error => {
                                        // Throw new error, that that is user friendly. When refresh token has expired.
                                        throw "Something is wrong with login. Please logout and log back in.";
                                    });

                            })
                        resolve(response)
                    }
                    else if (error.response && error.response.status === 404) {
                        return null;
                    }
                    return Promise.reject(parseError(error.response.data));
                });
        });
    }
    else if (error.response && error.response.status === 403) {
        return Promise.reject({
            requiresTwoFactor: (typeof error.response.data.tfaRequired !== "undefined" && error.response.data.tfaRequired === true),
            isLockedOut: (typeof error.response.data.isLockedOut !== "undefined" && error.response.data.isLockedOut),
            message: "Account is currently locked."
        });
    }
    else {
        return Promise.reject(parseError(error.response.data))
    }
})

export const http = instance