
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { paths } from '../constants';

import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';

import SecurityIcon from '@material-ui/icons/Security';
import FaceIcon from '@material-ui/icons/Face';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MailIcon from '@material-ui/icons/Mail';
import MoreIcon from '@material-ui/icons/MoreVert';
import Badge from '@material-ui/core/Badge';
import CameraIcon from '@material-ui/icons/Camera';
import AccountCircle from '@material-ui/icons/AccountCircle';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import RoomIcon from '@material-ui/icons/Room';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';


const menuList = [
    { Key: 1, Path: paths.ACCOUNT, Name: "Account", Icon: <AccountCircle /> },
    { Key: 2, Path: paths.PROFILE, Name: "Profile", Icon: <FaceIcon /> },
    { Key: 3, Path: paths.SECSETTINGS, Name: "Security Settings", Icon: <SecurityIcon /> },
    { Key: 4, Path: paths.LOGIN, Name: "Logout", Icon: <PowerSettingsNewIcon /> }

];

export const AccountMenu = (props) => (
    <Menu anchorEl={props.anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={props.isMenuOpen}
        onClose={props.handleMenuClose}>
        {/* <MenuItem component={Link} to="/account" onClick={props.handleMenuClose}>Account</MenuItem>
        <MenuItem component={Link} to="/profile" onClick={props.handleMenuClose}>Profile</MenuItem>
        <MenuItem component={Link} to="/security" onClick={props.handleMenuClose}>Security Settings</MenuItem>
        <MenuItem component={Link} to="/login" onClick={props.handleMenuClose}>Logout</MenuItem> */}
        {menuList.map((item) =>
            <MenuItem key={item.Key} component={Link} to={item.Path} onClick={props.handleMenuClose}>
                <IconButton color="inherit">
                {item.Icon}
                </IconButton>
                {item.Name}
            </MenuItem>
        )}
    </Menu>
);

AccountMenu.propTypes = {
    anchorEl: PropTypes.object,
    mobileOpen: PropTypes.bool,
    handleMenuClose: PropTypes.func
};

export const AccountMobileMenu = (props) => (
    <Menu
        anchorEl={props.mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={props.isMobileMenuOpen}
        onClose={props.handleMobileMenuClose}>
        {menuList.map((item) =>
            <MenuItem key={item.Key} component={Link} to={item.Path} onClick={props.handleMobileMenuClose}>
                <IconButton color="inherit">
                    {item.Icon}
                </IconButton>
                {item.Name}
            </MenuItem>
        )}
    </Menu>
);

AccountMobileMenu.propTypes = {
    mobileMoreAnchorEl: PropTypes.object,
    isMobileMenuOpen: PropTypes.bool,
    NotificationCount: PropTypes.string,
    MailCount: PropTypes.string,
    handleMobileMenuClose: PropTypes.func
};

const drawerMenuList = [
    { Key: 1, Path: paths.FIREARMS, Name: "Firearms", Icon: <CameraIcon /> },
    { Key: 2, Path: paths.FIRESESSIONS, Name: "Firearm Sessions", Icon: <LocationSearchingIcon /> },
    { Key: 3, Path: paths.RANGEGUN, Name: "Firing Range", Icon: <RoomIcon /> },
];

export const NavDrawerMenu = (props) => (

    <List>
        {drawerMenuList.map((item) => (
            <ListItem button key={item.Key} component={Link} to={item.Path}>
                <ListItemIcon>
                    {item.Icon}
                </ListItemIcon>
                <ListItemText primary={item.Name}/>
            </ListItem>
        ))}
    </List>
);

NavDrawerMenu.propTypes = {

};