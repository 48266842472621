import { http, buildRequestHeaders } from './axiosBase'

export const fireSessionApi = {
    all,
    recent,
    get,
    update,
    remove
};

const baseUrl = '/sessionsfirerange';

async function all() {
    var headers = await buildRequestHeaders({ addAuth: true });

    return http.get(baseUrl, {
        headers: headers,
    });
}

async function recent() {
    var headers = await buildRequestHeaders({ addAuth: true });

    return http.get(`${baseUrl}/recent`, {
        headers: headers,
    });
}

async function get(uid) {
    var headers = await buildRequestHeaders({ addAuth: true });

    return http.get(`${baseUrl}/${uid}`, {
        headers: headers,
    });
}

async function update(changes) {
    var headers = await buildRequestHeaders({ addAuth: true });

    return http.post(`${baseUrl}`, changes, {
        headers: headers,
    });
}

async function remove(uid) {
    var headers = await buildRequestHeaders({ addAuth: true });

    return http.delete(`${baseUrl}/${uid}`, {
        headers: headers,
    });
}