import { createBrowserHistory } from 'history'
import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router'
import { createLogger } from 'redux-logger';
import rootReducer from '../reducers';

export const history = createBrowserHistory()

export default function configureStore(preloadedState) {
    const loggerMiddleware = createLogger();
    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
        enhancers.push(window.devToolsExtension());
    }

    const store = createStore(
        rootReducer(history), // root reducer with router state
        preloadedState,
        compose(
            applyMiddleware(
                thunkMiddleware,
                loggerMiddleware,
                routerMiddleware(history), // for dispatching history actions
                // ... other middlewares ...
            ),
            ...enhancers
        ),
    )

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('../reducers', () => {
            const nextRootReducer = require('../reducers').default
            store.replaceReducer(nextRootReducer)
        })
    }

    return store
}

//export default function configureStore(history, initialState) {
//    const loggerMiddleware = createLogger();
//    const middleware = [
//		thunkMiddleware,
//		loggerMiddleware,
//		routerMiddleware(history)
//	];
		
//  // In development, use the browser's Redux dev tools extension if installed
//  const enhancers = [];
//  const isDevelopment = process.env.NODE_ENV === 'development';
//  if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
//    enhancers.push(window.devToolsExtension());
//  }

//    const store = createStore(
//        rootReducer,
//		compose(applyMiddleware(...middleware), ...enhancers)
//    );

//    if (module.hot) {
//        // Enable Webpack hot module replacement for reducers
//        module.hot.accept('../reducers', () => {
//            const nextRootReducer = require('../reducers').default
//            store.replaceReducer(nextRootReducer)
//        })
//    }

//    return store
//}