import React, { useEffect, useState } from "react";
import compose from 'recompose/compose';

import { Routes, Router, Route } from 'react-router-dom';

import { connect } from 'react-redux';

import { paths } from '../../constants';

import { history } from '../../helpers';
import { alertActions } from '../../actions';
import { ProtectedRoute, checkAuth } from '../../components/Routes';
import { BrowserRouter } from "react-router-dom";

/** Layouts **/
import LayoutAuth from "../../layouts/LayoutAuth";
import LayoutDashboard from "../../layouts/LayoutDashboard";

import { Account, Profile, SecuritySettings } from '../Account';
import { Login, LoginTFCode, Register, ConfirmEmail, ConfirmExternal } from '../Auth';
import { Dashboard } from '../Dashboard';

import { Firearms, RangeGun, FireSession, FireSessionDetails} from '../Firearms';


export default function App(props) {
    const { alert, classes, dispatch } = props;
    const reload = () => window.location.reload();

    useEffect(() => {
        checkAuth.authenticate(() => { });

        history.listen((location, action) => {
            dispatch(alertActions.clear());
        });

    }, []);


        return (
            <BrowserRouter location={history.location} history={history}>
                <Routes>
                    <Route element={<ProtectedRoute />}>
                        <Route path={paths.ROOT} element={<LayoutDashboard><Dashboard /></LayoutDashboard>} />
                        <Route path={paths.ACCOUNT} element={<LayoutDashboard><Account /></LayoutDashboard>} />
                        <Route path={paths.PROFILE} element={<LayoutDashboard><Profile /></LayoutDashboard>} />
                        <Route path={paths.SECSETTINGS} element={<LayoutDashboard><SecuritySettings /></LayoutDashboard>} />
                        <Route path={paths.RANGEGUN} element={<LayoutDashboard><RangeGun /></LayoutDashboard>} />
                        <Route path={paths.FIREARMS} element={<LayoutDashboard><Firearms /></LayoutDashboard>} />
                        <Route path={paths.FIRESESSIONS} element={<LayoutDashboard><FireSession /></LayoutDashboard>} />
                        <Route path={`${paths.FIRESESSIONSDETAILS}/:uid?`} element={<LayoutDashboard><FireSessionDetails /></LayoutDashboard>} />
                    </Route>
                    <Route path={paths.LOGIN} element={<LayoutAuth><Login /></LayoutAuth>} />
                    <Route path={paths.LOGINTFCODE} element={<LayoutAuth><LoginTFCode /></LayoutAuth>} />
                    <Route path={paths.CONFIRMEMAIL} element={<LayoutAuth><ConfirmEmail /></LayoutAuth>} />
                    <Route path={paths.CONFIRMEXTERNAL} element={<LayoutAuth><ConfirmExternal /></LayoutAuth>} />
                    <Route path={paths.REGISTER} element={<LayoutAuth><Register /></LayoutAuth>} />
                </Routes>
            </BrowserRouter>
        );
}

function mapStateToProps(state) {
    const { alert, navOpen, authentication } = state;
    return {
        alert,
        navOpen,
        authentication,
    };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App }; 