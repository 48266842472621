import { userConstants } from '../constants';
import { userLocalDb } from '../storelocal';

const initialState = {
    token: "",
    isLoggedIn: false,
    lockedOut: false,
    requiresTwoFactor: false
};

const initTwoFactState = {
    authAddUrl: '',
    sharedKey: '',
    success: false,
    code: '',
    verify: {
        success: false,
        message: ''
    },
    showVerifyDialog: false
};

export function authentication(state = initialState, action) {
    let token = userLocalDb.getAuthSync() || "";

    if (typeof token !== "undefined" && token.length > 0) {
        state.token = token;
    }

    switch (action.type) {
        case userConstants.LOGIN_SUCCESS:
            return {
                ...state,
                token: action.token,
                isLoggedIn: true
            };
        case userConstants.LOGIN_FAILURE:
            return {
                ...state,
                token: action.token,
                isLoggedIn: false,
                lockedOut: action.error.isLockedOut || false,
                requiresTwoFactor: action.error.requiresTwoFactor || false
            };
        case userConstants.LOGOUT:
            return initialState;
        default:
            return state;
    }
}

export function authenticationTwoFact(state = initTwoFactState, action) {
    switch (action.type) {
        case userConstants.TWOFACT_SUCCESS:
            return {
                ...state,
                authAddUrl: action.setup.AuthenticatorUri,
                sharedKey: action.setup.SharedKey,
                success: action.setup.Success,
                code: action.setup.Code,
                showVerifyDialog: true
            };
        case userConstants.TWOFACT_ERROR:
            return {
                success: action.setup.Success,
                showVerifyDialog: false
            };
        case userConstants.TWOFACT_RESET_SUCCESS:
            return {
                ...state,
                success: action.reset.Success,
            };
        case userConstants.TWOFACT_RESET_ERROR:
            return {
                success: action.reset.Success,
            };
        case userConstants.TWOFACT_VERIFY_SUCCESS:
            return {
                ...state,
                verify: action.verify,
                showVerifyDialog: false
            };
        case userConstants.TWOFACT_VERIFY_FAILURE:
            return {
                ...state,
                verify: action.error,
                showVerifyDialog: true
            };
        case userConstants.TWOFACT_RECOVERYCODES_SUCCESS:
            return {
                ...state,
                recoveryCode: action.recoveryCode,
            };
        case userConstants.TWOFACT_RECOVERYCODES_FAILURE:
            return {
                ...state,
                recoveryCode: action.error,
            };
        default:
            return state;
    }
}