
export const firearmLocalDb = {
    insert,
    get,
    all,
    delete: deleteItems,
}

function insert(param) {
    Promise.resolve(localStorage.setItem('firearms', JSON.stringify(param)));
}

function get() {
    return Promise.resolve(JSON.parse(localStorage.getItem('firearms')));
}

function all() {
    return Promise.resolve(JSON.parse(localStorage.getItem('firearms')));
}

function deleteItems() {
    Promise.resolve(localStorage.removeItem('firearms'));
}
