import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import Grid from '@material-ui/core/Grid';

import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

import { userService } from '../../services';

import { alertActions } from '../../actions';
import { InValidEmail } from '../../utils/forms.validation';
import { paths } from '../../constants';


const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
});

const defaultDetails = {
    "Username": "",
    "FirstName": "",
    "LastName": "",
    "Email": "",
    "Phone": "",
    "HasAuthenticator": false,
    "IsTwoFactorEnabled": false
};

export default function Profile(props) {
    const { classes } = props;
    const [loading, setLoading] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [details, setDetails] = useState(defaultDetails);
    const [errors, setErrors] = useState();

    const handleValidation = (e) => {
        let errors = {};

        //Email
        if (e.Email === "") {
            errors["Email"] = "Cannot be empty";
        }
        else {
            if (InValidEmail(e.Email)) {
                errors["Email"] = "Email is not valid";
            }
        }
        if (e.password === "") {
            errors["password"] = "Cannot be empty";
        }
        else if (e.confirmPassword === "") {
            errors["confirmPassword"] = "Cannot be empty";
        }
        else {
            if (e.password !== e.confirmPassword) {
                errors["confirmPassword"] = "Password does not match the confirm password.";
            }
        }

        setErrors(errors, []);
        return Object.keys(errors).length === 0;
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setDetails({
            ...details,
            [name]: value
        }, []);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        if (handleValidation(details)) {
            userService.update(details)
                .then(
                    success => {
                        props.dispatch(alertActions.success("Update Succeeded!"));
                    },
                    error => {
                        props.dispatch(alertActions.error(error));
                    }
                );
        }
    }

    useEffect(() => {
        if (loading) {
            userService.get()
                .then(resultResponse => {
                    if (resultResponse) {
                        setDetails(resultResponse, []);
                        setLoading(false);
                    }
                });
        }
    }, []);

    return (
        <Paper className={classes.root} elevation={1}>
            <Typography variant="h2" className={classes.header} gutterBottom>
                Profile
            </Typography>
            {loading &&
                <div>Loading...</div>}
            {!loading &&
                <form className={classes.form} name="form" onSubmit={e => handleSubmit(e)}>
                    <Grid className={classes.profileContainer} container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <FormControl margin="normal" required fullWidth>
                                <TextField id="FirstName" name="FirstName" type="text" label="First Name" value={details.FirstName} onClick={e => handleChange(e)}
                                    helperText={(submitted && typeof errors["password"] !== 'undefined' ? errors["FirstName"] : '')}
                                    error={(submitted && typeof errors["FirstName"] !== 'undefined')}
                                    autoComplete="FirstName" />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl margin="normal" required fullWidth>
                                <TextField id="LastName" name="LastName" type="text" label="Last Name" value={details.LastName}
                                    onChange={e => setDetails({ ...details, [e.target.name]: e.target.value }, [])}
                                    helperText={(submitted && typeof errors["password"] !== 'undefined' ? errors["LastName"] : '')}
                                    error={(submitted && typeof errors["LastName"] !== 'undefined')}
                                    autoComplete="LastName" />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl margin="normal" required fullWidth>
                                <TextField id="Username" name="Username" type="text" label="User Name" value={details.Username}
                                    onChange={e => setDetails({ ...details, [e.target.name]: e.target.value }, [])}
                                    helperText={(submitted && typeof errors["Username"] !== 'undefined' ? errors["Username"] : '')}
                                    error={(submitted && typeof errors["Username"] !== 'undefined')}
                                    autoComplete="Username" />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl margin="normal" required fullWidth>
                                <TextField id="PhoneNumber" name="PhoneNumber" type="text" label="Phone" value={details.PhoneNumber}
                                    onChange={e => setDetails({ ...details, [e.target.name]: e.target.value }, [])}
                                    helperText={(submitted && typeof errors["PhoneNumber"] !== 'undefined' ? errors["PhoneNumber"] : '')}
                                    error={(submitted && typeof errors["PhoneNumber"] !== 'undefined')}
                                    autoComplete="PhoneNumber" />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl margin="normal" required fullWidth >
                                <TextField id="Email" name="Email" label="Email" value={details.Email}
                                    onChange={e => setDetails({ ...details, [e.target.name]: e.target.value }, [])}
                                    helperText={(submitted && typeof errors["Email"] !== 'undefined' ? errors["Email"] : '')}
                                    error={(submitted && typeof errors["Email"] !== 'undefined')}
                                    autoComplete="Email" autoFocus />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <Button type="submit" size="medium" fullWidth variant="contained" color="primary" className={classes.submit}>Update Profile</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button size="medium" fullWidth variant="contained" color="secondary" className={classes.submit} component={Link} to={paths.ACCOUNT} >Cancel</Button>
                        </Grid>
                    </Grid>
                </form>
            }
        </Paper>
    );
}

const mapStateToProps = state => ({});


let connectedApp = compose(
    withStyles(styles, { name: 'Profile' }),
    connect(mapStateToProps, null)
)(Profile);

export { connectedApp as Profile };


