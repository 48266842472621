import React, { useEffect, useState } from "react";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import DialogResponsiveFuncBase from './DialogResponsiveFuncBase'

const styles = theme => ({
    recoverycodes: {

    }
});

function DialogAuthRecoveryCodes(props) {
    const { classes, recoveryCodes, dialogOpen, onClose } = props;
    //const items = recoveryCodes || []; // if there's no items, give empty array

    const renderContent = () => {
        if (Array.isArray(recoveryCodes) && recoveryCodes.length > 0) {
            return <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Box color="warning.main">
                        <strong>Make sure to not lose these codes</strong>
                        <p>If you lose your device and don't have the recovery codes you will lose access to your account.</p>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <ul>
                        {recoveryCodes.map((value, index) => {
                            return <li key={index.toString()}><code className={classes.recoverycodes}>{value}</code></li>;
                        })}
                    </ul>
                </Grid>
            </Grid>;
        }
        else {
            return <Grid container spacing={1}>
                <Grid item xs={12}>
                    <p>Your authenticator app has been verified.</p>
                </Grid>
            </Grid>;
        }
    }

    return (
        <DialogResponsiveFuncBase
            openDialog={dialogOpen}
            title={Array.isArray(recoveryCodes) && recoveryCodes.length > 0 ?"Two Factor Authentication Recovery Codes" : "Authentication Verified"}
            onClose={onClose}
            closeButtonName="Ok"
        >
            {renderContent()}
        </DialogResponsiveFuncBase>
    );
}

export default withStyles(styles)(DialogAuthRecoveryCodes);

