import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DialogResponsiveFuncBase from './DialogResponsiveFuncBase'

const styles = theme => ({
});

function DialogConfirmation(props) {

    const { classes, dialogTitle, dialogMessage, dialogBtnName, dialogOpen, onClose, handleSubmit } = props;

    useEffect(() => { });

    return (
        <DialogResponsiveFuncBase
            openDialog={dialogOpen}
            onClose={onClose}
            title={dialogTitle}
            actionButtons={<Button onClick={handleSubmit} color="primary">{dialogBtnName || 'Ok'}</Button>}
            closeButtonName="Cancel">
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    {dialogMessage || ""}
                </Grid>
            </Grid>
        </DialogResponsiveFuncBase>
    );
}


export default withStyles(styles)(DialogConfirmation);
