import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { CardSimple } from '../../components/CardSimple'
import { paths } from '../../constants';

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },

    },
});

export default function Account(props) {
    const navigate = useNavigate();
    const { classes, history} = props;

    const handleClick = (e) => {
        navigate(e);
    };

    return (
        <Grid className={classes.root} container spacing={8}>
            <Grid item sm={6} xs={12}>
                <CardSimple
                    title='My Profile'
                    content='Make sure to keep all your personal info up to date.'
                    buttonName='Got to Profile'
                    onButtonActionClick={e => handleClick(paths.PROFILE)} />
            </Grid>
            <Grid item sm={6} xs={12}>
                <CardSimple
                    title='Security Settings'
                    content='Make sure to keep all your personal info up to date.'
                    buttonName='Security Settings'
                    onButtonActionClick={e => handleClick(paths.SECSETTINGS)} />
            </Grid>
            <Grid item sm={6} xs={12}>
                <CardSimple title='Test' subtitle='subtitle' content='test' />
            </Grid>
        </Grid>
    );
}

const mapStateToProps = state => ({});

let connectedApp = compose(
    withStyles(styles, { name: 'Account' }),
    connect(mapStateToProps, null)
)(Account);

export { connectedApp as Account };