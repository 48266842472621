import { venueApi } from '../api';
import { firearmLocalDb } from '../storelocal';
import qs from 'querystringify';

export const venueService = {
    all,
    get,
    update,
    remove
};

async function all(venueType) {
    return venueApi.all(venueType)
        .then(response => {
            return response;
        });
}

function get(uid) {
    return venueApi.get(uid)
        .then(firearm => {
            return firearm;
        });
}

function update(model) {
    return venueApi.update(model)
        .then(successful => {
            return successful;
        });
}

function remove(uid) {
    return venueApi.remove(uid)
        .then(successful => {
            if (successful) {
                return uid;
            }
        });
}