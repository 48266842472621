
export const userLocalDb = {
    insert,
    get,
    delete: deleteUser,
    update,
    getId,
    getIdSync,
    insertId,
    deleteId,
    //getToken,
    //insertToken,
    //getTokenSync,
    //deleteToken,
    insertAuth: insertToken,
    getAuth: getToken,
    getAuthSync: getTokenSync,
    deleteAuth: deleteToken
}

function insert(user) {
    Promise.resolve(localStorage.setItem('user', JSON.stringify(user)));
}

function get() {
    return Promise.resolve(JSON.parse(localStorage.getItem('user')));
}

function getUserId() {
    return Promise.resolve(JSON.parse(localStorage.getItem('user')));
}

function update(user) {
    let current = JSON.parse(localStorage.getItem('user'));
    if (user.Username && user.Username.length > 0 && current.Username !== user.Username) {
        current.Username = user.Username;
    }

    if (user.FirstName && user.FirstName.length > 0 && current.FirstName !== user.FirstName) {
        current.FirstName = user.FirstName;
    }

    if (user.LastName && user.LastName.length > 0 && current.LastName !== user.LastName) {
        current.LastName = user.LastName;
    }

    if (user.Email && user.Email.length > 0 && current.Email !== user.Email) {
        current.email = user.Email;
    }

    if (user.Phone && user.Phone.length > 0 && current.Phone !== user.Phone) {
        current.Phone = user.Phone;
    }

    if (user.HasAuthenticator && user.HasAuthenticator.length > 0 && current.HasAuthenticator !== user.HasAuthenticator) {
        current.HasAuthenticator = user.HasAuthenticator;
    }

    if (user.IsTwoFactorEnabled && user.IsTwoFactorEnabled.length > 0 && current.IsTwoFactorEnabled !== user.IsTwoFactorEnabled) {
        current.IsTwoFactorEnabled = user.IsTwoFactorEnabled;
    }

    insert(current);
}

function deleteUser() {
    Promise.resolve(localStorage.removeItem('user'));
}

function insertToken(token) {
    return Promise.resolve(localStorage.setItem('auth', JSON.stringify(token)));
}

function getToken() {
    return Promise.resolve(getTokenSync());
}
function getTokenSync() {
    return JSON.parse(localStorage.getItem('auth'));
}

function deleteToken() {
    Promise.resolve(localStorage.removeItem('auth'));
}

function insertId(id) {
    return Promise.resolve(localStorage.setItem('id', JSON.stringify(id)));
}

function getId() {
    return Promise.resolve(getIdSync());
}
function getIdSync() {
    return JSON.parse(localStorage.getItem('id'));
}

function deleteId() {
    Promise.resolve(localStorage.removeItem('id'));
}


function insertAuthentication(loggedIn) {
    return Promise.resolve(localStorage.setItem('auth', JSON.stringify(loggedIn)));
}

function getAuthentication() {
    return Promise.resolve(getAuthenticationSync());
}
function getAuthenticationSync() {
    return JSON.parse(localStorage.getItem('auth'));
}

function deleteAuthentication() {
    Promise.resolve(localStorage.removeItem('auth'));
}