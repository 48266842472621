import { fireSessionApi } from '../api';
import { firearmsService } from './';

export const fireSessionService = {
    all,
    get,
    recent,
    update,
    remove
};

function all() {
    return fireSessionApi.all()
        .then(response => {
            return response;
        });
}

function recent() {
    return fireSessionApi.recent()
        .then(response => {
            return response;
        });
}

function get(uid) {
    return fireSessionApi.get(uid)
        .then(response => {
            if (response) {
                return response;
            }
        });

}

function update(model) {

    return fireSessionApi.update(model)
        .then(successful => {
            return successful;
        });
}

function remove(uid) {
    return fireSessionApi.remove(uid)
        .then(successful => {
            if (successful) {
                return uid;
            }
        });
}