export const paths= {
    ROOT: '/',
    ACCOUNT: '/account',
    PROFILE: '/profile',
    LOGIN: '/login',
    LOGINTFCODE: '/loginWith2fa',
    CONFIRMEMAIL: '/confirmemail',
    CONFIRMEXTERNAL: '/confirmexternal',
    REGISTER: '/register',
    SECSETTINGS: '/security',
    RANGEGUN: '/firerange',
    FIRESESSIONS: '/firesessions',
    FIRESESSIONSDETAILS: '/firesession/details',
    FIREARMS: '/firearms'
};