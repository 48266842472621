export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    
    LOGOUT: 'USERS_LOGOUT',

    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    GET_REQUEST: 'USERS_GET_REQUEST',
    GET_SUCCESS: 'USERS_GET_SUCCESS',
    GET_FAILURE: 'USERS_GET_FAILURE',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    UPDATE_REQUEST: 'USERS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'USERS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'USERS_UPDATE_FAILURE',

    TWOFACT_REQUEST: 'USERS_TWOFACT_REQUEST',
    TWOFACT_SUCCESS: 'USERS_TWOFACT_SUCCESS',
    TWOFACT_FAILURE: 'USERS_TWOFACT_FAILURE',

    TWOFACT_VERIFY_REQUEST: 'USERS_TWOFACT_VERIFY_REQUEST',
    TWOFACT_VERIFY_SUCCESS: 'USERS_TWOFACT_VERIFY_SUCCESS',
    TWOFACT_VERIFY_FAILURE: 'USERS_TWOFACT_VERIFY_FAILURE',

    TWOFACT_RESET_REQUEST: 'USERS_TWOFACT_RESET_REQUEST',
    TWOFACT_RESET_SUCCESS: 'USERS_TWOFACT_RESET_SUCCESS',
    TWOFACT_RESET_FAILURE: 'USERS_TWOFACT_RESET_FAILURE',

    TWOFACT_RECOVERYCODES_REQUEST: 'USERS_TWOFACT_RECOVERYCODES_REQUEST',
    TWOFACT_RECOVERYCODES_SUCCESS: 'USERS_TWOFACT_RECOVERYCODES_SUCCESS',
    TWOFACT_RECOVERYCODES_FAILURE: 'USERS_TWOFACT_RECOVERYCODES_FAILURE',

};
