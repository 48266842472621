import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withStyles from '@material-ui/core/styles/withStyles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { userActions } from '../../actions';
import { userService } from '../../services';
import { paths } from '../../constants';

const styles = theme => ({
    avatar: {
        margin: theme.spacing(),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(),
    },
    submit: {
    }
});

export default function  Login(props) {
    const navigate = useNavigate();
    const { loggingIn, classes, dispatch} = props;
    const [submitted, setSubmitted] = useState(false);

    const [details, setDetails] = useState({
        username: '',
        password: '',
        submitted: false
    });

    const handleSubmit = (e) =>  {
        e.preventDefault();

        setSubmitted(true);

        if (details.username && details.password) {
            //dispatch(userActions.login(username, password));
            userService.login(details.username, details.password)
                .then(
                    user => {
                        navigate(paths.ROOT);
                    },
                    error => {
                        if (typeof error.requiresTwoFactor !== "undefined" && error.requiresTwoFactor === true) {
                            navigate(paths.LOGINTFCODE, { email: details.username });

                        } else {
                            alertActions.error(error.message);
                        }
                    }
                );
        }
    }

   const handleExterClick = (e) =>  {
        const { dispatch } = this.props;


        if (typeof e !== 'undefined' && e.length > 0) {
        //    console.log(e);
            let url = `${process.env.REACT_APP_API_BASE}/Auth/Connect?provider=${e}&returnUrl=${encodeURIComponent(`/auth.html`)}`;

            let loginWindow = window.open(url, '', 'width=600,height=400');

            if (window.addEventListener) {
                window.addEventListener('message', function (ev) {

                    let externalValue = ev.source.message;

                    if (typeof externalValue !== "undefined" && typeof externalValue.token !== "undefined") {
                        dispatch(userActions.saveToken(externalValue.token));
                        loginWindow.close();

                    }

                }, false);
            }
        }
        return false;
    }

    useEffect(() => {
        // reset login status
        dispatch(userActions.logout());
    }, []);

    return (
        <React.Fragment>
            <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">Login</Typography>
            <form className={classes.form} name="form" onSubmit={e => { handleSubmit(e) }}>
                <Grid container spacing={1}>
                    {/*<Grid item xs={4}>*/}
                    {/*    <Button fullWidth variant="contained" color="primary" onClick={() => { this.handleExterClick("Google") }}>Google</Button>*/}
                    {/*</Grid>*/}
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={12}>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="username">Email Address</InputLabel>
                            <Input id="username" name="username" value={details.username}
                                onChange={e => setDetails({ ...details, [e.target.name]: e.target.value }, [])}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="password">Password</InputLabel>
                            <Input name="password" type="password" value={details.password}
                                onChange={e => setDetails({ ...details, [e.target.name]: e.target.value }, [])}
                                />
                        </FormControl>
                    </Grid>
                    {/*<Grid item xs={12}>*/}
                    {/*    <FormControlLabel*/}
                    {/*        control={<Checkbox value="remember" color="primary" />}*/}
                    {/*        label="Remember me"*/}
                    {/*    />*/}
                    {/*</Grid>*/}
                    <Grid item xs={6}>
                        <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>Login</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button size="medium" fullWidth variant="contained" color="secondary" className={classes.register} component={Link} to={paths.REGISTER} >Register</Button>
                    </Grid>
                </Grid>
            </form>
        </React.Fragment>
    );
}

function mapStateToProps(state) {
    const { authentication } = state;
    return {
        authentication,
    };
}

let connectedApp = compose(
    withStyles(styles, { name: 'Login' }),
    connect(mapStateToProps, null)
)(Login);

export { connectedApp as Login }; 