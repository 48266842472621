import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import { firearmsService } from '../../services';

import DialogFirearms from '../../components/Dialogs/DialogFirearms'
import DialogConfirmation from '../../components/Dialogs/DialogConfirmation'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';

import Fab from '@material-ui/core/Fab';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    header: {
        'font-size': '25pt'
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    alertText: {
        color: theme.palette.error.dark,
    },
    fabGreen: {
        color: theme.palette.common.white,
    }
});

export default function Firearms(props) {
    const { classes } = props;
    const [loading, setLoading] = useState(true);
    const [firearmId, setFirearmId] = useState("");
    const [deleteId, setDeleteId] = useState("");
    const [firearms, setFirearms] = useState([]);
    const [isDetailDialogOpen, setIsDetailDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

    const handleEditDialogShow = (e) => {
        if (typeof e !== "undefined") {
            setFirearmId(e);
        }
        setIsDetailDialogOpen(true);
    }

    const handleEditDialogClose = (e) => {
        setFirearmId("");
        setIsDetailDialogOpen(false);

        firearmsService.all()
            .then(firearmResponse => {
                if (firearmResponse) {
                    setFirearms(firearmResponse, []);
                }
            });
    }

    const handleDeleteDialogClose = (e) => {
        firearmsService.remove(deleteId)
            .then(
                success => {
                    setDeleteId("");
                    setIsDeleteDialogOpen(false);
                    firearmsService.all()
                        .then(firearmResponse => {
                            if (firearmResponse) {
                                setFirearms(firearmResponse, []);
                            }
                        });
                },
                error => {
                    setIsDeleteDialogOpen(false);
                }
            );
    }

    const handleDeleteDialogShow = (e) => {
        if (typeof e !== "undefined") {
            setDeleteId(e);
        }
        setIsDeleteDialogOpen(true);
    }

    useEffect(() => {
        if (loading) {
            firearmsService.all()
                .then(firearmResponse => {
                    if (firearmResponse) {
                        setFirearms(firearmResponse, []);
                        setLoading(false);
                    }
                });
        }
    }, []);

    return (
        <div>
            <Paper className={classes.root} elevation={1}>
                <Typography variant="h2" className={classes.header} gutterBottom>
                    Firearms
                </Typography>
                {loading &&
                    <div>Loading...</div>}
                {!loading && firearms.length === 0 &&
                    <div>No Records Found.</div>}
                {!loading && firearms.length > 0 &&
                    <List dense={true}>
                        {firearms.map((item) =>
                            <ListItem key={item.UId}>
                                <ListItemText
                                    primary={item.Name}
                                    secondary={item.DatePurchased} />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="edit" onClick={e => handleEditDialogShow(item.UId)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton edge="end" aria-label="delete" onClick={e => handleDeleteDialogShow(item.UId)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>,
                        )}
                    </List>
                }
                <Fab aria-label="Add" className={classes.fab} color="primary" onClick={e => handleEditDialogShow()}>
                    <AddIcon />
                </Fab>
            </Paper>
            {isDetailDialogOpen &&
                <DialogFirearms
                    dialogOpen={isDetailDialogOpen}
                    onClose={() => {
                        setFirearmId("");
                        setIsDetailDialogOpen(false);
                    }}
                    handleSubmit={handleEditDialogClose}
                    Uid={firearmId}
                />}
            {isDeleteDialogOpen &&
                <DialogConfirmation
                    dialogOpen={isDeleteDialogOpen}
                    onClose={() => {
                        setDeleteId("");
                        setIsDeleteDialogOpen(false);
                    }}
                    handleSubmit={handleDeleteDialogClose}
                    dialogMessage={<div>Are you sure you want to delete this? <br/> <strong className={classes.alertText}>Warning this will permanantly delete and remove it from anything it was attached to.</strong></div>}
                    dialogTitle={"Delete Firearm?"}
                    dialogBtnName={"Delete"}
                />}
        </div>
    );


}

const mapStateToProps = state => ({});

let connectedApp = compose(
    withStyles(styles, { name: 'Firearms' }),
    connect(mapStateToProps, null)
)(Firearms);

export { connectedApp as Firearms };