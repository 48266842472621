import { http, buildRequestHeaders } from './axiosBase';
export const accountApi = {
    update,
    getUserInfo,
    chgPassword,
    getTwoFactAuth,
    verifyTwoFactAuth,
    disableTwoFactAuth,
    resetTwoFactAuth,
    getRecoveryCodes
};

const accGetUrl = "/account/userinfo";
const accUpdateUrl = "/account/update";
const accChgPassUrl = '/account/chgpassword';

const tfaGetUrl = '/twofactorauth/setupauthenticator';
const tfaDistableUrl = '/twofactorauth/disableauthenticator';
const tfaResetUrl = '/twofactorauth/resetauthenticator';
const tfaGenCodesUrl = '/twofactorauth/GenerateRecoveryCodes';
const verifyTwoFactAuthApiUrl = "/twofactorauth/verifyauthenticator";


async function update(bodyData) {
    var headers = await buildRequestHeaders({ addAuth: true });

    return http.patch(accUpdateUrl, bodyData,
        { headers: headers }).then(response => {
            return response.succeeded;
        });
}
async function chgPassword(bodyData) {
    var headers = await buildRequestHeaders({ addAuth: true });

    return http.post(accChgPassUrl, bodyData,
        { headers: headers }).then(response => {
            return response;
        });
}
async function getUserInfo() {
    var headers = await buildRequestHeaders({ addAuth: true });

    return http.get(accGetUrl, {
        headers: headers,
    });
}
async function verifyTwoFactAuth(code) {
    var headers = await buildRequestHeaders({ addAuth: true });

    return http.post(verifyTwoFactAuthApiUrl, { "Code": code }, { headers: headers })
        .then(response => {
            return response
        });
}
async function getTwoFactAuth() {
    var headers = await buildRequestHeaders({ addAuth: true });

    return http.get(tfaGetUrl, {
        headers: headers,
    });
}
async function disableTwoFactAuth() {
    var headers = await buildRequestHeaders({ addAuth: true });

    return http.post(tfaDistableUrl, {}, { headers: headers });
}
async function resetTwoFactAuth() {
    var headers = await buildRequestHeaders({ addAuth: true });

    return http.post(tfaResetUrl, {}, { headers: headers });
}
async function getRecoveryCodes() {
    var headers = await buildRequestHeaders({ addAuth: true });

    return http.get(tfaGenCodesUrl, {
        headers: headers,
    });
}