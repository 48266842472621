import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withStyles from '@material-ui/core/styles/withStyles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { userActions } from '../../actions';
import { paths } from '../../constants';

const styles = theme => ({
    avatar: {
        margin: theme.spacing(),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(),
    },
    submit: {
    }
});

class LoginTFCode extends React.Component {
    constructor(props) {
        super(props);

        let emailAddress = "";

        if (props.location.state.email !== "undefined" && props.location.state.email.length === 0) {
            // reset login status
            this.props.dispatch(userActions.logout());
        } else {
            emailAddress = props.location.state.email;
        }

        this.state = {
            tfaCode: '',
            email: emailAddress,
            rememberMachine: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        const { dispatch } = this.props;
        const { tfaCode, email, rememberMachine } = this.state;
        if (tfaCode) {
            dispatch(userActions.loginTwoFact(tfaCode, email, rememberMachine));
        }
    }

    render() {
        const { classes } = this.props;
        const { tfaCode } = this.state;

        return (
            <React.Fragment>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">Two Factor Authentication</Typography>
                <form className={classes.form} name="form" onSubmit={this.handleSubmit}>
                    <Grid container spacing={1}>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={12}>
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="tfaCode">Code</InputLabel>
                                <Input id="tfaCode" name="tfaCode" value={tfaCode} onChange={this.handleChange} autoFocus
                                />
                            </FormControl>
                        </Grid>
                        {/*<Grid item xs={12}>*/}
                        {/*    <FormControlLabel*/}
                        {/*        control={<Checkbox value="rememberMachine" color="primary" />}*/}
                        {/*        label="Remember this machine"*/}
                        {/*    />*/}
                        {/*</Grid>*/}
                        <Grid item xs={12}>
                            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>Login</Button>
                        </Grid>
                    </Grid>
                </form>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    const { authentication } = state;
    return {
        authentication,
    };
}

let connectedApp = compose(
    withStyles(styles, { name: 'LoginTFCode' }),
    connect(mapStateToProps, null)
)(LoginTFCode);

export { connectedApp as LoginTFCode }; 