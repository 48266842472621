import { http } from './axiosBase'

export const authApi = {
    login,
    loginExter,
    loginTwoFact,
    register,
    confirmemail,
    confirmExtern
};

const conEmailApiUrl = `/auth/confirmemail`;
const conExternApiUrl = `/auth/externalloginconfirm`;
const authApiUrl = `/auth/authenticate`;
const registerApiUrl = `/auth/register`;
const connectApiUrl = `/auth/connect`;

async function login(username, password) {
    return http.post(authApiUrl, {
        "email": username,
        "password": password
    }).then(response => {
        return response;
    });
}

async function loginTwoFact(code, email, rememberMachine) {
    return http.post(authApiUrl, {
        "Email": email,
        "TwoFactorCode": code,
        "RememberMachine": rememberMachine
    }).then(response => {
        return response
    });
}

async function loginExter(provider) {
    return http.get(`${connectApiUrl}?provider=${provider}`)
        .then(response => {
            return response
        });
}

async function register(bodyData) {
    return http.post(registerApiUrl, bodyData)
        .then(response => {
            return response
        });
}

async function confirmemail(getParams) {
    return http.get(`${conEmailApiUrl}?${getParams}`)
        .then(response => {
            return response
        });
}

async function confirmExtern() {
    return http.get(conExternApiUrl)
        .then(response => {
            return response
        });
}