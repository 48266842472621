import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import QRCode from 'qrcode.react';
import DialogResponsiveFuncBase from './DialogResponsiveFuncBase'

import { userService } from '../../services';


const styles = theme => ({
    qrcode: {
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block'
    }
});

const defaultDetails = {
    authAddUrl: '',
    sharedKey: '',
    success: false,
    code: '',
    verify: {
        success: false,
        message: ''
    }
};



function DialogAuthSetup(props) {
    const { classes, tfAuth, dialogOpen, onClose, handleSubmit, handleAuthErrors } = props;
    const [details, setDetails] = useState(defaultDetails);
    const [errors, setErrors] = useState();
    const [twoFactAuthCode, setTwoFactAuthCode] = useState("");


    const handleSetupAuthSubmit = (e) => {
        e.preventDefault();
        if (typeof twoFactAuthCode !== 'undefined' && twoFactAuthCode !== '') {
            let code = twoFactAuthCode.replace(/\s/g, ''); // remove any spaces.
            userService.verifyTwoFactAuth(code)
                .then(
                    verify => {
                        userService.refreshInfo()
                            .then(
                                user => {
                                    if (Array.isArray(verify.RecoveryCodes) && verify.RecoveryCodes.length) {
                                        handleSubmit(verify.RecoveryCodes);
                                    } else {
                                        handleSubmit();
                                    }
                                }).catch(
                                    error => {
                                        handleAuthErrors(error);
                                    });

                    },
                    error => {
                        handleAuthErrors(error);
                    }
                );
        }

    }

    useEffect(() => {
        if (details.sharedKey.length === 0 && details.authAddUrl.length === 0) {
            if (typeof tfAuth.SharedKey !== "undefined" && tfAuth.SharedKey.length > 0) {
                setDetails({ ...details, ["sharedKey"]: tfAuth.SharedKey });
            }

            if (typeof tfAuth.AuthenticatorUri !== "undefined" && tfAuth.AuthenticatorUri.length > 0) {
                setDetails({ ...details, ["authAddUrl"]: tfAuth.AuthenticatorUri });
            }
        }
    });


    return (
        <DialogResponsiveFuncBase
            openDialog={dialogOpen}
            onClose={onClose}
            title={"Add Two Factor Authentication"}
            actionButtons={<Button autoFocus form="send-code" type="submit" color="primary"> Verify</Button>}
            closeButtonName="Cancel">
            <form name="send-code" id="send-code" onSubmit={e => { handleSetupAuthSubmit(e) }}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <p>Scan the QR Code or enter this key <kbd>{details.sharedKey}
                        </kbd> into your two factor authenticator app. Spaces and casing do not matter.</p>
                    </Grid>
                    <Grid item xs={12}>
                        <QRCode includeMargin={true}
                            className={classes.qrcode} value={details.authAddUrl || ""} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl margin="normal" fullWidth>
                            <TextField id="code" name="code" label="Verification Code"
                                onChange={e => setTwoFactAuthCode(e.target.value, [])}
                                helperText={(typeof errors !== "undefined" && typeof errors["code"] !== "undefined" ? errors["code"] : '')}
                                error={(typeof errors !== "undefined" && typeof errors["code"] !== "undefined")}
                                required />
                        </FormControl>
                    </Grid>
                </Grid>
            </form>
        </DialogResponsiveFuncBase>);
}

const mapStateToProps = state => ({});

export default withStyles(styles)(DialogAuthSetup);
let connectedApp = compose(
    withStyles(styles, { name: 'DialogAuthSetup' }),
    connect(mapStateToProps, null)
)(DialogAuthSetup);

export { connectedApp as DialogAuthSetup };
