import { http, buildRequestHeaders } from './axiosBase'

export const venueApi = {
    all,
    get,
    update,
    remove
};

const baseUrl = '/venue';


async function all(venueType) {
    var headers = await buildRequestHeaders({ addAuth: true });

    return http.get(`${baseUrl}/Type/${venueType}`, {
        headers: headers,
    });
}

async function get(uid) {
    var headers = await buildRequestHeaders({ addAuth: true });

    return http.get(`${baseUrl}/${uid}`, {
        headers: headers,
    });
}

async function update(changes) {
    var headers = await buildRequestHeaders({ addAuth: true });

    return http.post(`${baseUrl}`, changes, {
        headers: headers,
    });
}

async function remove(uid) {
    var headers = await buildRequestHeaders({ addAuth: true });

    return http.delete(`${baseUrl}/${uid}`, {
        headers: headers,
    });
}