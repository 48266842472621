import React, { useEffect, useState } from "react";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import DialogResponsiveFuncBase from './DialogResponsiveFuncBase'
import moment from 'moment';
import { firearmsService } from '../../services';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider, KeyboardDatePicker
} from '@material-ui/pickers';

const styles = theme => ({

});

const defaultDetails = {
    "UId": "",
    "Name": "",
    "Brand": "",
    "Model": "",
    "Caliber": "",
    "DatePurchased": "",
    "Year": "",
    "Notes": ""
};

 function DialogFirearms(props) {

    const { classes, Uid, dialogOpen, onClose, handleSubmit} = props;
    const [details, setDetails] = useState(defaultDetails);
    const [submitted, setSubmit] = useState();
    const [errors, setErrors] = useState();

    const handleFormSubmit = (e) => {
        e.preventDefault();

        let firearmChanges = {
            UId: details.UId || "",
            Name: details.Name || "",
            Brand: details.Brand || "",
            Model: details.Model || "",
            Caliber: details.Caliber || "",
            DatePurchased: details.DatePurchased || "",
            Year: details.Year || "",
            Notes: details.Notes || ""
        }

        firearmsService.update(firearmChanges).then(successful => {
            if (successful) {
                handleSubmit();
            }
        });
    }

    useEffect(() => {
        if (details.Name.length == 0) {
            if (typeof Uid !== "undefined" && Uid.length > 0) {
                firearmsService.get(Uid)
                    .then(resultResponse => {
                        if (resultResponse) {
                            setDetails(resultResponse, []);
                        }
                    });
            }
        }
    });


    return (
        <DialogResponsiveFuncBase
            openDialog={dialogOpen}
            onClose={onClose}
            title={"Firearm - Edit"}
            actionButtons={<Button autoFocus form="update-firearm" type="submit" color="primary"> Update</Button>}
            closeButtonName="Cancel">
            <form name="update-firearm" id="update-firearm" onSubmit={e => { handleFormSubmit(e) }}>
                <input type="hidden" id="UId" name="UId" value={details.UId ?? ""} />
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <FormControl margin="normal" fullWidth>
                            <TextField id="Name" name="Name" type="text" label="Name"
                                value={details.Name} onChange={e => setDetails({ ...details, [e.target.name]: e.target.value }, [])}
                                helperText={(submitted && typeof errors["name"] !== 'undefined'
                                    ? errors["name"]
                                    : '')}
                                error={(submitted && typeof errors["name"] !== 'undefined')}
                                required />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl margin="normal" fullWidth>
                            <TextField id="Brand" name="Brand" type="text" label="Brand"
                                value={details.Brand} onChange={e => setDetails({ ...details, [e.target.name]: e.target.value }, [])}
                                helperText={(submitted && typeof errors["brand"] !== 'undefined'
                                    ? errors["brand"]
                                    : '')}
                                error={(submitted && typeof errors["brand"] !== 'undefined')}
                                 />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl margin="normal" fullWidth>
                            <TextField id="Model" name="Model" type="text" label="Model"
                                value={details.Model} onChange={e => setDetails({ ...details, [e.target.name]: e.target.value }, [])}
                                helperText={(submitted && typeof errors["model"] !== 'undefined'
                                    ? errors["model"]
                                    : '')}
                                error={(submitted && typeof errors["model"] !== 'undefined')}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl margin="normal" fullWidth>
                            <TextField id="Caliber" name="Caliber" type="text" label="Caliber"
                                value={details.Caliber} onChange={e => setDetails({ ...details, [e.target.name]: e.target.value }, [])}
                                helperText={(submitted && typeof errors["caliber"] !== 'undefined'
                                    ? errors["caliber"]
                                    : '')}
                                error={(submitted && typeof errors["caliber"] !== 'undefined')}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl margin="normal" fullWidth>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker disableToolbar variant="inline" format="MM/dd/yyyy" autoOk={true}
                                    id="DatePurchased" name="DatePurchased" label="Purchased/Rented"
                                    value={(typeof details.DatePurchased !== 'undefined' && details.DatePurchased.length > 0) ? details.DatePurchased : null}
                                    onChange={date => setDetails({ ...details, ["DatePurchased"]: moment(date, 'YYYY-MM-DD').format('MM/DD/YYYY') }, [])}
                                    keyboardbuttonprops={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl margin="normal" fullWidth>
                            <TextField id="Year" name="Year" type="number" label="Year"
                                value={details.Year} onChange={e => setDetails({ ...details, [e.target.name]: e.target.value }, [])}
                                helperText={(submitted && typeof errors["year"] !== 'undefined'
                                    ? errors["year"]
                                    : '')}
                                error={(submitted && typeof errors["year"] !== 'undefined')}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl margin="normal" fullWidth>
                            <TextField id="Notes" name="Notes" label="Notes" multiline rows={4}
                                value={details.Notes} onChange={e => setDetails({ ...details, [e.target.name]: e.target.value }, [])}
                                helperText={(submitted && typeof errors["notes"] !== 'undefined'
                                    ? errors["notes"]
                                    : '')}
                                error={(submitted && typeof errors["notes"] !== 'undefined')}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </form>
        </DialogResponsiveFuncBase>
    );
}

export default withStyles(styles)(DialogFirearms);
