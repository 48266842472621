import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { ChartLineSimple } from '../../components'
import { CardSimple } from '../../components/CardSimple'
import { paths } from '../../constants';
import { fireSessionService } from '../../services';

const styles = theme => ({
    tableContainer: {
        height: 320,
    },
    sessionBody: {
        "margin-bottom": 0,
    },
    sessionHeader: {
        "margin-bottom":0,
    },
    sessionDate: {
        "margin-top": 0,
    },
    sessionNotes: {
    }
});

const activeListings = [
    { name: 'Mon', Visits: 2200, Orders: 3400 },
    { name: 'Tue', Visits: 1280, Orders: 2398 },
    { name: 'Wed', Visits: 5000, Orders: 4300 },
    { name: 'Thu', Visits: 4780, Orders: 2908 },
    { name: 'Fri', Visits: 5890, Orders: 4800 },
    { name: 'Sat', Visits: 4390, Orders: 3800 },
    { name: 'Sun', Visits: 4490, Orders: 4300 },
];

const defaultValues = {

};

export default function Dashboard(props) {
    const navigate = useNavigate();
    const { classes } = props;
    const [recentArms, setRecentArms] = useState(<p>No Records Found.</p>);
    const [recentSession, SetRecentSession] = useState(<p>No Records Found.</p>);

    const handleGetSession = () => {
        fireSessionService.recent()
            .then(response => {
                if (response) {
                    handleCardDisplay(response);
                }
            });
    }
    const handleClick = (e) => {
        navigate(e);
    };

    const handleCardDisplay = (response) => {
        if (typeof response === 'undefined') {
            return;
        }

        if ((typeof response.Firearms !== 'undefined' && response.Firearms.length != 0)) {
            setRecentArms(<List dense={true}>
                {response.Firearms.map((item) =>
                    <ListItem key={item.UId}>
                        <ListItemText
                            primary={item.Name}
                            secondary={item.BrandName + " - " + item.Model} />
                    </ListItem>,
                )}
            </List>);
        }
        if (typeof response.Venue !== 'undefined') {

            SetRecentSession(<Grid item className={classes.sessionBody} xs={12}>
                <h3 className={classes.sessionHeader}>{response.Venue.Name}</h3>
                <p className={classes.sessionDate}>{response.DateSession ?? ""}</p>
                <p className={classes.sessionNotes}>{response.Notes ?? ""}</p>
            </Grid>);
        }
    }

    useEffect(() => {
        handleGetSession();
    }, []);



    return (

        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant="h4" gutterBottom component="h2">
                    Recent Activity
                </Typography>
            </Grid>
            <Grid item sm={6} xs={12}>
                <CardSimple
                    title='Session'
                    isComplex={true}
                    content={recentSession}
                    buttonName='Got to Sessions'
                    onButtonActionClick={e => handleClick(paths.FIRESESSIONS)} />
            </Grid>
            <Grid item sm={6} xs={12}>
                <CardSimple
                    title='Armory'
                    isComplex={true}
                    content={recentArms}
                    buttonName='Got to Armory'
                    onButtonActionClick={e => handleClick(paths.FIREARMS)} />
            </Grid>
            {/*<Grid item xs={12}>*/}
            {/*    <Typography variant="h4" gutterBottom component="h2">*/}
            {/*        Active Listings*/}
            {/*    </Typography>*/}
            {/*    <div className={classes.tableContainer}>*/}
            {/*        <ChartLineSimple lineData={activeListings} />*/}
            {/*    </div>*/}
            {/*</Grid>*/}
        </Grid>
    );
}

const mapStateToProps = state => ({});

let connectedApp = compose(
    withStyles(styles, { name: 'Dashboard' }),
    connect(mapStateToProps)
)(Dashboard);

export { connectedApp as Dashboard };