import { accountApi, authApi } from '../api';
import { userLocalDb, firearmLocalDb} from '../storelocal';
import qs from 'querystringify';

export const userService = {
    get,
    userAuth,
    login,
    loginTwoFact,
    loginToken,
    update,
    logout,
    register,
    refreshInfo,
    confirmEmail,
    confirmExtern,
    changePassword,
    getTwoFactAuth,
    disableTwoFactAuth,
    resetTwoFactAuth,
    verifyTwoFactAuth,
    getRecoveryCodes
};

function login(username, password) {
    return authApi.login(username, password)
        .then(user => {
            if (user.token) {
                loginToken(user.token);
                refreshInfo();
            }
            return user;
        });
}

function loginTwoFact(code, email, rememberMachine) {
    return authApi.loginTwoFact(code, email, rememberMachine)
        .then(user => {
            if (user.token) {
                loginToken(user.token);
                refreshInfo();
            }
            return user;
        });
}


function loginCookie(username, password) {
    return accountApi.login(username, password)
        .then(user => {
            if (user.successful) {
                //loginToken(user.token);
                loginSuccessful();

            }
            return user;
        });
}

function loginToken(token) {
    return userLocalDb.insertAuth(token);
}

function loginSuccessful() {
    return userLocalDb.insert(true);
}

function logout() {
    userLocalDb.delete();
    userLocalDb.deleteAuth();
    userLocalDb.deleteId();
    firearmLocalDb.delete();
}

function get() {
    return userLocalDb.get()
        .then(user => {
            if (user) {
                return user;
            }

            return refreshInfo();
        });
}

function refreshInfo() {
    return accountApi.getUserInfo()
        .then(user => {
            if (user) {
                let userData = {
                    Username: user.Username || "",
                    FirstName: user.FirstName || "",
                    LastName: user.LastName || "",
                    Email: user.Email || "",
                    Phone: user.Phone || "",
                    HasAuthenticator: user.HasAuthenticator || false,
                    IsTwoFactorEnabled: user.IsTwoFactorEnabled || false
                }
                userLocalDb.insert(userData);
                userLocalDb.insertId(user.Id || "");

            }
            return user;
        });
}

async function userAuth() {
    let auth = await userLocalDb.getAuth();
    return (auth !== null && typeof auth !== "undefined" && auth === true);
}

async function update(user) {
    //let userId = await userLocalDb.getId();


    //let updateData = { UserId: userId };
    let updateData = {};

    if (user.Username && user.Username.length > 0) {
        updateData.Username = user.Username;
    }

    if (user.FirstName && user.FirstName.length > 0) {
        updateData.FirstName = user.FirstName;
    }

    if (user.LastName && user.LastName.length > 0) {
        updateData.LastName = user.LastName;
    }

    if (user.Email && user.Email.length > 0) {
        updateData.Email = user.Email;
    }

    if (user.IsTwoFactorEnabled && user.IsTwoFactorEnabled.length > 0) {
        updateData.IsTwoFactorEnabled = user.IsTwoFactorEnabled;
    }

    if (user.HasAuthenticator && user.HasAuthenticator.length > 0) {
        updateData.HasAuthenticator = user.HasAuthenticator;
    }

    return accountApi.update(updateData)
        .then(succeeded => {
            userLocalDb.update(user);
            return succeeded;
        });
}

function changePassword(passwordChange) {
    let data = {
    };

    if (passwordChange.conPassword && passwordChange.conPassword.length > 0) {
        data.ConfirmPassword = passwordChange.conPassword;
    }
    if (passwordChange.oldPassword && passwordChange.oldPassword.length > 0) {
        data.OldPassword = passwordChange.oldPassword;
    }
    if (passwordChange.newPassword && passwordChange.newPassword.length > 0) {
        data.Newpassword = passwordChange.newPassword;
    }

    return accountApi.chgPassword(data);
}

function register(user) {
    return authApi.register(user);
}

function confirmEmail(confirm) {
    let query = qs.stringify(confirm);
    return authApi.confirmemail(query);
}

function confirmExtern() {
    return authApi.confirmExtern();
}

function getTwoFactAuth() {
    return accountApi.getTwoFactAuth()
        .then(user => {
            return user;
        });
}

function disableTwoFactAuth() {
    return accountApi.disableTwoFactAuth()
        .then(user => {
            return user;
        });
}

function resetTwoFactAuth() {
    return accountApi.resetTwoFactAuth()
        .then(response => {
            return response;
        });
}

function verifyTwoFactAuth(code) {
    return accountApi.verifyTwoFactAuth(code)
        .then(
            code => {
                return code;
            },
            error => {
                throw error.messages.StatusMessage;
            })
}

function getRecoveryCodes() {
    return accountApi.getRecoveryCodes()
        .then(codes => {
            return codes;
        });
}
