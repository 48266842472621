import { firearmsApi } from '../api';
import { firearmLocalDb } from '../storelocal';
import qs from 'querystringify';

export const firearmsService = {
    all,
    get,
    update,
    remove
};

async function all() {
   return firearmLocalDb.all()
        .then(localResult => {
            if (localResult) {
                return localResult;
            }

            return firearmsApi.all()
                .then(apiResult => {
                    if (apiResult) {
                        firearmLocalDb.insert(apiResult)
                    }
                    return apiResult;
                })
                .then(results => {
                    return results;
                });
        });
}

function get(uid) {
    return firearmsApi.get(uid)
        .then(firearm => {
            return firearm;
        });
}

function update(changes) {
    let updateModel = {
        UId: changes.UId || "",
        Name: changes.Name || "",
        Brand: changes.Brand || "",
        Model: changes.Model || "",
        Caliber: changes.Caliber || "",
        DatePurchased: changes.DatePurchased,
        Year: "",
        Notes: changes.Notes || ""
    };

    updateModel.Year = changes.Year;


    return firearmsApi.update(updateModel)
        .then(successful => {
            firearmLocalDb.delete();
            all();
            return successful;
        });
}

function remove(uid) {
    return firearmsApi.remove(uid)
        .then(successful => {
            if (successful) {
                firearmLocalDb.delete();
                all();

                return uid;
            }
        });
}