import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import { authentication, authenticationTwoFact } from './authentication.reducer';

import { registration } from './registration.reducer';
import { user } from './users.reducer';
import { alert } from './alert.reducer';

const reducers = {
    authentication,
    authenticationTwoFact,
    registration,
    user,
    alert,
};

const rootReducer = (history) => combineReducers({
    router: connectRouter(history),
    ...reducers,
})

export default rootReducer;