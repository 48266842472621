import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import FolderIcon from '@material-ui/icons/Folder';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';

import DialogRangeGun from '../../components/Dialogs/DialogRangeGun'
import DialogConfirmation from '../../components/Dialogs/DialogConfirmation'

import { venueService } from '../../services';

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    header: {
        'font-size': '25pt'
    },
    qrcode: {
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block'
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    alertText: {
        color: theme.palette.error.dark,
    },
    fabGreen: {
        color: theme.palette.common.white,
    }
});

export default function RangeGun(props) {
    const { classes } = props;
    const [loading, setLoading] = useState(true);
    const [venueId, setVenueId] = useState("");
    const [deleteId, setDeleteId] = useState("");
    const [records, setRecords] = useState([]);
    const [isDetailDialogOpen, setIsDetailDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

    useEffect(() => {
        if (loading) {
            venueService.all(1)
                .then(resultResponse => {
                    if (resultResponse) {
                        setRecords(resultResponse, []);
                        setLoading(false);
                    }
                });
            setLoading(false);
        }
    }, []);

    const handleEditDialogShow = (e) => {
        if (typeof e !== "undefined") {
            setVenueId(e);
        }
        setIsDetailDialogOpen(true);
    }

    const handleEditDialogClose = (e) => {
        setVenueId("");
        setIsDetailDialogOpen(false);

        venueService.all(1)
            .then(resultResponse => {
                if (resultResponse) {
                    setRecords(resultResponse, []);
                    setLoading(false);
                }
            });
    }

    const handleDeleteDialogShow = (e) => {
        if (typeof e !== "undefined") {
            setDeleteId(e);
        }
        setIsDeleteDialogOpen(true);
    }

    const handleDeleteDialogClose = (e) => {
        venueService.remove(deleteId)
            .then(
                success => {
                    setDeleteId("");
                    setIsDeleteDialogOpen(false);
                    venueService.all(1)
                        .then(resultResponse => {
                            if (resultResponse) {
                                setRecords(resultResponse, []);
                                setLoading(false);
                            }
                        });
                },
                error => {
                    setIsDeleteDialogOpen(false);
                }
            );
    }

    return (
        <div>
            <Paper className={classes.root} elevation={1}>
                <Typography variant="h2" className={classes.header} gutterBottom>
                    Gun Range Locations
                </Typography>
                {loading &&
                    <div>Loading...</div>}
                {!loading && records.length === 0 &&
                    <div>No Records Found.</div>}
                {!loading && records.length > 0 &&
                    <List dense={true}>
                        {records.map((item) =>
                            <ListItem key={item.UId}>
                                <ListItemAvatar>
                                    <Avatar>
                                        <FolderIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={item.Name}
                                    secondary={item.DateAdded}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="edit" onClick={e => handleEditDialogShow(item.UId)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton edge="end" aria-label="delete" onClick={e => handleDeleteDialogShow(item.UId)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>,
                        )}
                    </List>}
            </Paper>
            <Fab aria-label="Add" className={classes.fab} color="primary" onClick={e => handleEditDialogShow()}>
                <AddIcon />
            </Fab>
            {isDetailDialogOpen &&
                <DialogRangeGun
                    dialogOpen={isDetailDialogOpen}
                    onClose={() => {
                        setVenueId("");
                        setIsDetailDialogOpen(false);
                    }}
                    handleSubmit={handleEditDialogClose}
                    Uid={venueId}
                />}
            {isDeleteDialogOpen &&
                <DialogConfirmation
                    dialogOpen={isDeleteDialogOpen}
                    onClose={() => {
                        setVenueId("");
                        setIsDeleteDialogOpen(false);
                    }}
                    handleSubmit={handleDeleteDialogClose}
                    dialogMessage={<div>Are you sure you want to delete this? <br /> <strong className={classes.alertText}>Warning this will permanantly delete and remove it from anything it was attached to.</strong></div>}
                    dialogTitle={"Delete Range?"}
                    dialogBtnName={"Delete"}
                />}
        </div>
    );
}

const mapStateToProps = state => ({});

let connectedApp = compose(
    withStyles(styles, { name: 'RangeGun' }),
    connect(mapStateToProps, null)
)(RangeGun);

export { connectedApp as RangeGun };